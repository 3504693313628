<template>
  <q-card style="width: 800px; max-width: 850px">
    <q-bar>
      <q-space />
      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip class="bg-white text-primary">Fechar</q-tooltip>
      </q-btn>
    </q-bar>
    <q-card-section>
      <h6 class="text-h6" v-if="groupForEdit">Editar Grupo</h6>
      <h6 class="text-h6" v-else>Criar Grupo</h6>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <div class="content-new-training flex row col-12">
        <div class="content-thumb-training col-4">
          <div class="col-12 flex row q-pa-sm">
            <div class="col-12">
              <label class="title-form">Capa</label>
            </div>
            <div class="col-12 thumb-training q-mt-sm shadow-3">
              <q-img
                height="150px"
                :src="imageUrl"
                fit="contain"
                style="background-color: #e5e5e5"
              />
            </div>
            <div class="col-12 btn-thumb-training flex justify-between q-mt-md">
              <q-btn
                @click="sendEventAddImage"
                class="q-mt-xs"
                unelevated
                color="default-pink"
                text-color="default-white"
                label="Escolher imagem"
              />
              <input
                style="display: none"
                ref="refFileInput"
                @change="onEventFilePicked"
                type="file"
                name="upload"
                accept="image/*"
              />

              <q-btn
                @click="sendEventRemoveImage"
                class="q-mt-xs"
                outline
                color="default-pink"
                label="Remover"
              />
            </div>
          </div>
        </div>
        <div class="content-form-training col-8">
          <div class="form-input col-12 column q-mt-sm">
            <div class="row">
              <span class="title-input-form">Nome do Grupo</span>
            </div>
            <div class="row">
              <q-input
                class="col-12"
                bg-color="grey-3"
                color="default-pink"
                v-model="group.name"
                dense
                type="text"
                outlined
                rounded
                autofocus
                required
                :rules="[
                  (val) => {
                    if (val.trim().length <= 2)
                      return 'O nome do grupo não pode ser vazio ou menor que três caractres';
                  },
                ]"
              />
            </div>
          </div>
          <div class="form-input col-12 column">
            <div class="row">
              <span class="title-input-form">Descrição do Grupo</span>
            </div>
            <div class="row">
              <q-input
                class="col-12"
                bg-color="grey-3"
                color="default-pink"
                v-model="group.description"
                dense
                type="text"
                outlined
                rounded
              />
            </div>
          </div>
          <div class="form-input col-12 column q-mt-sm">
            <div class="row">
              <label for="" class="title-input-form"
                >Selecione um segmento</label
              >
            </div>
            <div class="row">
              <q-select
                color="default-pink"
                class="col-12"
                outlined
                rounded
                dense
                multiple
                use-chips
                use-input
                emit-value
                map-options
                @filter="filterFnBranches"
                v-model="group.branches"
                :options="listBranches"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Não encontrado
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </div>
          <div class="form-input col-12 column q-mt-sm">
            <div class="row">
              <span class="title-input-form"
                >Selecione todos os usuários do grupo</span
              >
            </div>
            <div class="row">
              <q-select
                color="default-pink"
                dense
                outlined
                rounded
                class="col-12"
                input-debounce="0"
                use-input
                @filter="filterFnGroups"
                v-model="selectedGroup"
                :options="userGroup"
                @update:model-value="listAllUsersByCompany()"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem resultados
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </div>
          <div class="form-input col-12 column q-mt-sm">
            <div class="row">
              <span class="title-input-form">Usuários</span>
            </div>
            <div class="row">
              <q-select
                color="default-pink"
                dense
                outlined
                rounded
                v-model="userToGroup"
                use-input
                hide-selected
                class="col-12"
                :disable="group == null ? true : false"
                input-debounce="0"
                label="Selecione os usuários"
                :options="optionsUsers"
                @update:model-value="toggleEstudant()"
                @filter="
                  (val, update) =>
                    filterFnUsers(val, update, optionsUsers, listUsers)
                "
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem resultados
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="row">
              <span class="title-input-form">Usuários do Grupo</span>
            </div>
            <div class="form-input col-12 column">
              <Table
                class="fit"
                :list="group.members"
                :columns="columnsTable"
                :addEdit="false"
                :addRemove="true"
                @disabled="(student) => disabled(student)"
              >
                <template v-slot:body="props">
                  <div>
                    <q-btn
                      dense
                      round
                      color="default-pink"
                      title="Remover"
                      flat
                      @click="() => disabled(props.props.row)"
                      icon="o_delete_outline"
                    />
                    <q-toggle
                      v-if="groupForEdit"
                      v-model="props.props.row.is_admin"
                      :label="`${
                        props.props.row.is_admin == true
                          ? 'Admin do chat'
                          : 'Usuário padrão'
                      }`"
                      color="default-pink"
                    />
                  </div>
                </template>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn
        flat
        v-if="groupForEdit"
        label="Editar Grupo"
        @click="$emit('create', group)"
      />
      <q-btn flat v-else label="Criar Grupo" @click="$emit('create', group)" />

      <q-btn flat label="apagar grupo" @click="$emit('remove')" />
    </q-card-actions>
  </q-card>
  <q-inner-loading
    :showing="loading"
    label="Por favor aguarde..."
    label-class="text-teal"
    label-style="font-size: 1.1em"
  />
</template>

<script>
import { ref, reactive, onMounted, watch } from "vue";
import { useQuasar } from "quasar";
import Table from "@/components/shared/tables/TableDynamic.vue";
import { ENV } from "@/utils/env";
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";

export default {
  name: "CreateGroupChat",
  components: {
    Table,
  },
  props: {
    listUsers: Array,
    groupForEdit: {
      type: Object,
      default: null,
    },
  },
  emits: ["create", "remove"],
  setup(props) {
    const urlAwsBucket = ENV.URL_AWS_BUCKET;
    const $q = new useQuasar();
    const groupServices = new GroupServices();
    const userServices = new UserServices();

    let imageUrl = ref("");
    let refFileInput = ref(null);
    let actionInDialog = ref("Criar Grupo");

    let userToGroup = ref("");
    let selectedGroup = ref(null);
    let userGroup = ref([]);
    let listGroups = ref([]);

    let optionsUsers = ref(props.listUsers);
    let loading = ref(false);
    let listBranches = ref([]);
    let optionListBranches = ref([]);

    let group = reactive({
      conversationId: null,
      name: "",
      branches: [],
      description: "",
      photo_path: null,
      is_group: true,
      members: [],
    });

    function _populateGroup() {
      group.conversationId = props.groupForEdit.conversationId;
      group.name = props.groupForEdit.name;
      group.description = props.groupForEdit.description;
      group.photo_path = props.groupForEdit.photo_path;
      imageUrl.value = urlAwsBucket + props.groupForEdit.photo_path;
      props.groupForEdit.members.forEach((g) => {
        let member = {
          user_id: g.user_id,
          label: g.name,
          is_admin: g.is_admin,
        };
        group.members.push(member);
      });
    }

    async function _getAllUserGroups() {
      let result = await groupServices.listGroups();

      result.data.forEach((group) => {
        userGroup.value.push({
          value: group.id,
          label: group.group_name,
          branch_id: group.branch_id,
        });
      });
      listGroups.value = userGroup.value;
    }

    async function getBranches() {
      await groupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function listAllUsersByCompany() {
      //group.members = [];
      let userData = JSON.parse(localStorage.getItem("vuex")).userdata;
      await userServices
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (
              item.group_id.includes(selectedGroup.value.value) &&
              item.id != userData.id
            ) {
              let member = {
                user_id: item.id,
                label: item.first_name + " " + item.last_name,
                is_admin: false,
              };

              if (!group.members.some((e) => e.user_id === member.user_id)) {
                group.members.push(member);
              }
            }
          });
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    onMounted(() => {
      if (props.groupForEdit != null) {
        _populateGroup();
      }
      _getAllUserGroups();
      getBranches();
    });

    watch(
      () => group.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length != oldBranch.length) {
          userGroup.value = listGroups.value;
        }

        if (newBranch.length != 0) {
          userGroup.value = listGroups.value.filter((group) => {
            return newBranch.includes(group.branch_id);
          });
        }
      }
    );

    let columnsTable = ref([
      {
        name: "user_id",
        align: "left",
        label: "ID",
        field: "user_id",
        sortable: false,
      },
      {
        name: "label",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.label,
        sortable: true,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);

    function toggleEstudant() {
      let existingUserInGroup = group.members.find((user) => {
        return user.user_id == userToGroup.value;
      });

      if (existingUserInGroup == null) {
        let member = {
          user_id: userToGroup.value.value,
          label: userToGroup.value.label,
          is_admin: false,
        };
        group.members.push(member);
      }
    }

    function loadingCreateGroup(state) {
      loading.value = state;
    }

    function filterFnUsers(val, update, optionsUser, list) {
      if (val === "") {
        update(() => {
          optionsUsers.value = list;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsUsers.value = list.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (group.branches.length > 0) {
            userGroup.value = listGroups.value.filter((g) => {
              return group.branches.includes(g.branch_id);
            });
          }
          userGroup.value = listGroups.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        userGroup.value = listGroups.value.filter((v) => {
          if (group.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              group.branches.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    function test(test) {
      console.log(test);
    }

    function disabled(user) {
      $q.notify({
        message: `Deseja remover o usuário ${user.label}?`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              _removeStudent(user.user_id);
            },
          },
        ],
      });
    }

    function _removeStudent(userId) {
      group.members = group.members.filter((member) => {
        return member.user_id != userId;
      });
    }

    const sendEventRemoveImage = () => {
      imageUrl.value = "";
      refFileInput.value = null;
      group.photo_path = "";
    };

    const onEventFilePicked = (event) => {
      const files = event.target.files;
      group.photo_path = files[0];
      const filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Por favor adicione um arquivo válido");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (event) => {
        imageUrl.value = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
    };

    const sendEventAddImage = () => {
      refFileInput.value.click();
    };

    return {
      group,
      columnsTable,
      userToGroup,
      userGroup,
      selectedGroup,
      toggleEstudant,
      listAllUsersByCompany,
      actionInDialog,
      disabled,
      sendEventAddImage,
      onEventFilePicked,
      sendEventRemoveImage,
      loadingCreateGroup,
      imageUrl,
      refFileInput,
      loading,
      filterFnUsers,
      optionsUsers,
      listBranches,
      test,
      filterFnBranches,
      filterFnGroups,
    };
  },
};
</script>
<style></style>
