<template>
  <div :class="[isSent ? 'message message-reverse' : 'message']">
    <div v-if="!isSent" class="message-avatar">
      <UserPhoto
        :idUser="messageUser.user_id"
        :avatarUser="messageUser.avatar_location"
      />
    </div>

    <div
      :class="[isSent ? 'message-balloon' : 'message-balloon  message-recive']"
    >
      <small class="name-send-mensage"> {{ messageUser.name }}</small>

      <slot name="message"></slot>
    </div>
    <div class="message-date">
      <small>{{
        messageUser.deleted_message ? "" : messageUser.created_at
      }}</small>
    </div>

    <!-- <q-chat-message
    :name="messageUser.name"
    :text="messageUser.message"
    size="6"
    :sent="isSent"
    bg-color="default-send"
  >
  
    <template v-slot:stamp>{{
      messageUser.deleted_message ? "" : messageUser.created_at
    }}</template>
    <template v-slot:avatar>
      <UserPhoto 
        :idUser="messageUser.user_id"
      />
    </template>
    <slot name="message"></slot>
  </q-chat-message> -->
  </div>
</template>

<script>
//----IMAGES
import defaultImg from "../../assets/images/avatar_default.svg";

//----COMPNENTS
import UserPhoto from "@/components/shared/card/UserPhoto.vue";

//----UTILS
import { ENV } from "@/utils/env";

//----VUEJS
import { ref, defineAsyncComponent } from "vue";

export default {
  name: "ChatMessageBalloon",
  components: {
    UserPhoto: defineAsyncComponent(() =>
      import("@/components/shared/card/UserPhoto.vue")
    ),
  },
  props: {
    messageUser: Object,
    isSent: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const urlAwsBucket = ENV.URL_AWS_BUCKET;
    let showUserDetail = ref(false);
    return {
      //----IMAGES
      defaultImg,

      //----VARIABLES
      showUserDetail,

      //----CONSTANTS
      urlAwsBucket,
    };
  },
};
</script>

<style lang="scss" scoped>
.avatar-chat {
  height: 48px;
  width: 48px;
  border-radius: 48px;
  overflow: hidden;
  margin-right: 12px;

  .user-image {
    height: 100%;
  }
}
.avatar-sent {
  margin-left: 12px;
}
.avatar-recive {
  margin-right: 12px;
}
.message {
  display: flex;
  flex-direction: initial;
  margin: 15px 0 0 0;
}

.message-reverse {
  flex-direction: row-reverse;
}

.message-avatar {
  display: flex;
  align-items: flex-end;
}
.message-balloon {
  max-width: 30vw;
  min-width: 70px;

  background: rgba(var(--q-secundary-rgb), 0.2);
  border-radius: 15px;

  padding: 10px 15px;
  margin: 0 5px;
  position: relative;

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    opacity: 0.6;
    margin: 0;
  }
}

.message-recive {
  background: rgba(0, 0, 0, 0.03);
}

.message-date {
  display: flex;
  align-items: center;
  small {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    color: #000000;

    opacity: 0.3;
  }
}

.name-send-mensage {
  display: block;
  margin-bottom: 8px;
}
</style>
