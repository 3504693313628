<template>
  <div class="q-mg-md row justify-center chat-conversantions">
    <div class="row col-12 q-pb-sm">
      <q-select
        outlined
        rounded
        v-model="userSelected"
        use-input
        hide-selected
        class="col-11 search-user text-default-pink"
        style="margin: auto; font-weight: bold; border: 0"
        input-debounce="0"
        label-color="default-pink"
        label="Pessoas"
        :options="optionsUser"
        @update:model-value="(val) => $emit('selectUser', val)"
        @filter="
          (val, update) => filterFnUsers(val, update, options, listUsers)
        "
        :dense="true"
        :options-dense="false"
      >
        <template v-slot:append>
          <q-icon name="search" @click.stop />
        </template>
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey"> Sem resultados </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>
    <q-list class="chat-container full-height col-11">
      <q-item
        clickable
        v-ripple
        class="chat-attendance q-mt-sm flex items-center"
        :active="chatSelected == index"
        active-class="active-chat-attendance"
        :id="index"
        v-for="(chat, index) in chats"
        :key="chat.conversationId"
        @click="
          () => {
            chatSelected = index;
            $emit('onClickChat', { chat: chat, index: index });
          }
        "
      >
        <q-tooltip
          class="bg-default-pink"
          v-if="chat.lastMessage != null && chat.lastMessage != ''"
          >{{ chat.lastMessage }}</q-tooltip
        >
        <q-item-section top avatar>
          <q-avatar class="avatar-chat">
            <img
              class="image-user-chat"
              :src="
                chat.photo_path == '' || chat.photo_path == undefined
                  ? defaultImg
                  : urlAwsBucket + chat.photo_path
              "
            />
            <div
              :class="['avatar-chat-online', chat.online ? '' :'avatar-chat-offline']" v-show="chat.is_group != true"
            ></div>
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <q-item-label lines="1">{{ chat.name }}</q-item-label>
          <q-item-label caption lines="1">{{ chat.lastMessage }}</q-item-label>
        </q-item-section>

        <q-item-section side top>
          <q-item-label caption>{{ chat.timeLastMessage }}</q-item-label>
          <q-icon v-show="chat.isNewMessage" name="info" color="warning" />
        </q-item-section> 
      </q-item>
      <q-separator />
    </q-list>
  </div>
</template>

<script>
//----IMAGES
import defaultImg from "../../../assets/images/avatar_default.svg";

//----UTILS
import { ENV  } from "@/utils/env";

//----VUEJS
import { ref } from "vue";

export default {
  name: "ChatUsers",
  props: {
    chats: Array,
    listUsers: Array,
    options: Array,
  },

  emits: ["onClickChat", "selectUser"],

  setup(props) {
    //----CONSTANTS
    const urlAwsBucket =ENV.URL_AWS_BUCKET;

    //----VARIABLES
    let userSelected = ref();
    let chatSelected = ref(null);
    let optionsUser = ref(props.listUsers);
    //-----FUNCTIONS
    function filterFnUsers(val, update, options, list) {
      if (val === "") {
        update(() => {
          optionsUser.value = list;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsUser.value = list.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    return {
      //----IMAGES
      defaultImg,

      //----VARIABLES
      userSelected,
      urlAwsBucket,
      chatSelected,
      optionsUser,

      //----FUNCTIONS
      filterFnUsers,
    };
  },
};
</script>

<style lang="scss" scoped>
.chat-conversantions {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 10px 0px;
  border-radius: 20px;
}

.chat-container {
  max-height: 68vh;
  padding-right: 10px;
  overflow-y: scroll;

  .image-user-chat {
    height: 100% !important;
    width: auto !important;
  }

  .chat-attendance {
    border-radius: 22px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.07);
  }

  .active-chat-attendance {
    color: white;
    background: #000000;
  }
}

.search-user {
  color: var(--q-secundary);
  font-weight: bold;
}

.avatar-chat {
  position: relative;

  .avatar-chat-online {
    width: 15px;
    height: 15px;

    background: #09b709;
    position: absolute;
    bottom: 0;
    right: 0;

    border: 2px solid white;
    border-radius: 15px;
  }

  .avatar-chat-offline {
    background: red;
  }
}
</style>
